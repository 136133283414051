<template>
  <div>
    <v-toolbar
      class="primary"
      short
    >
      <v-app-bar-nav-icon style="color:white;"></v-app-bar-nav-icon>

      <v-toolbar-title style="color:white;">
        Features List
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        color="secondary"
        class="me-3"
        small
        :to="{ name: 'catalogos-ctfeatures-add' }"
      >
        <v-icon
          size="18"
          class="me-1"
        >
          {{ icons.mdiPlus }}
        </v-icon>
        <span>Create Feature</span>
      </v-btn>
    </v-toolbar>
    <v-card id="market-list">
      <!-- table -->
      <v-data-table
        v-model="selectedTableData"
        :headers="featureTableColumns"
        :items="featureListTable"
        :options.sync="options"
        :server-items-length="totalInvoiceListTable"
        :loading="loading"
        class="text-no-wrap"
      >
        <!-- trending header -->
        <template #[`header.trending`]>
          <v-icon size="22">
            {{ icons.mdiTrendingUp }}
          </v-icon>
        </template>

        <!-- id -->
        <template #[`item.id`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'catalogos-ctfeatures-edit', params: { id: item.id } }"
          >
            {{ item.id }}
          </router-link>
        </template>
        <!-- status -->
        <template #[`item.isActive`]="{item}">
          <v-chip
            small
            :color="statusColor[status[item.isActive]]"
            :class="`${statusColor[status[item.isActive]]}--text`"
            class="v-chip-light-bg"
          >
            {{ status[item.isActive] }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
} from '@mdi/js'

import { onUnmounted, ref } from '@vue/composition-api'

// store module
import store from '@/store'

// store module
import ctFeatureStore from './ctFeatureStore'

// composition function
import useFeaturesList from './useFeaturesList'

export default {
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, ctFeatureStore)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      featureListTable,
      searchQuery,
      featureTableColumns,
      options,
      statusFilter,
      totalInvoiceListTable,
      loading,
      selectedTableData,
    } = useFeaturesList()

    const selectedAction = ref('')
    const urlHangfire = `${process.env.VUE_APP_API_URL}/hangfire`

    const statusColor = {
      /* eslint-disable key-spacing */
      Active: 'success',
      Inactive: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      selectedTableData,
      totalInvoiceListTable,
      featureListTable,
      selectedAction,
      featureTableColumns,
      searchQuery,
      statusFilter,
      options,
      loading,
      statusColor,
      urlHangfire,
      status: {
        true: 'Active',
        false: 'Inactive',
      },

      // icons
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss">

.iframe-wrapper .vue-friendly-iframe, .iframe-wrapper .vue-friendly-iframe iframe {
    height: 600px;
    width: 100%;
}

</style>
