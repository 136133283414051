import store from '@/store'

// import { fieldPatternMatcher } from '@casl/ability'
import { ref, watch } from '@vue/composition-api'

export default function useFeaturesList() {
  const featureListTable = ref([])

  // Table Handlers
  const featureTableColumns = [

    { text: '#ID', align: 'start', value: 'id' },
    { text: 'Market', value: 'market' },
    { text: 'Feature', value: 'featureName' },
    { text: 'Value', value: 'featureValue' },
    { text: 'Active', value: 'isActive' },
  ]

  const searchQuery = ref('')
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const totalInvoiceListTable = ref(0)
  const loading = ref(false)
  const statusFilter = ref(null)
  const selectedTableData = ref([])

  const fetchFeatures = () => {
    store
      .dispatch('app-invoice/fetchFeatures', {
        q: searchQuery.value,
        options: options.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, total } = response
        const filteredData = data
        featureListTable.value = filteredData
        totalInvoiceListTable.value = total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, statusFilter, options], () => {
    // start loading
    loading.value = true
    selectedTableData.value = []
    fetchFeatures()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    featureTableColumns,
    searchQuery,
    options,
    featureListTable,
    statusFilter,
    totalInvoiceListTable,
    loading,
    selectedTableData,
    fetchFeatures,
  }
}
